<template>
<div class="container">
    <h2>Oops!</h2>
    <hr>
    <h4>Something's Broken, Page not found</h4>
    <div>
        <img src="https://media0.giphy.com/media/hIfDZ869b7EHu/giphy.gif?cid=ecf05e47bb7nrdrjemtc3aari9tn1jz4cczczdvdn1q00wi2&amp;rid=giphy.gif&amp;ct=g" alt="broken bike GIF">
    </div>
    <p>
        <a href="https://giphy.com/gifs/bike-broken-ring-hIfDZ869b7EHu">via GIPHY</a>
    </p>
    <p>
        Feel free to contact us if you feel that something's wrong. We are a team of one who do this on the side, so it may be possible that something has broken down and we don't know about it yet.
    </p>
</div>
</template>
